import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class AppPrivacy extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="アプリケーションプライバシーポリシー" />
        <header id="header">
          <h1>アプリケーションプライバシーポリシー</h1>
          <p><Link to="/">cretia studio</Link></p>
        </header>
        <div id="main">
          <section id="content" className="main">
            <p>cretia studio（以下「本アプリの運営者」といいます）は、本アプリの運営者がスマートフォン等で提供するアプリケーション（以下「本アプリ」といいます）の利用者（以下「ユーザー」といいます）のプライバシーを尊重し、ユーザーの利用者情報の管理に細心の注意を払うものとします。</p>
            <h4>1. 取得される情報の項目</h4>
            <p>本アプリのご利用に際して、以下の利用者情報を自動的に取得します。また、本アプリ内で別途取得する情報がある場合は、本アプリ利用時に記載された情報も含まれます。</p>
            <ol>
              <li>端末情報(端末個体識別ID, OS, OSバージョン, 地域, 言語 等)</li>
              <li>アプリ内で生成されたユーザーID、操作ログ情報、利用履歴等</li>
              <li>広告配信のためのアクセス履歴、位置情報等</li>
            </ol>
            <h4>2. 利用目的</h4>
            <p>本アプリの運営者は、ユーザーが本アプリを利用することで取得した利用者情報を次の目的のために利用させて頂きます。</p>
            <ol>
              <li>本アプリをご提供、ご案内するため</li>
              <li>本アプリの改善、新サービスの開発等に役立てるため</li>
              <li>広告配信事業者による広告配信のため</li>
              <li>本アプリの利用規約に違反する利用形態を防止するため</li>
              <li>ユーザーの利用形態等の統計データを作成するため</li>
            </ol>
            <h4>3. 利用制限</h4>
            <p>本アプリの運営者は、ユーザーが本アプリを利用することで取得した利用者情報について、以下に掲げる場合を除き、上記利用目的の達成に必要な範囲内において使用するものとします。</p>
            <ol>
              <li>法令に基づく場合</li>
              <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            </ol>
            <h4>4. 利用者情報の第三者への提供</h4>
            <p>本アプリの運営者は、ユーザーの利用者情報について、原則としてユーザーの同意なくして第三者に提供しません。但し、以下の場合はこの限りではありません。</p>
            <ol>
              <li>本アプリの運営者が本アプリ等の一部を業務委託し、委託業務の遂行に必要な範囲で提供する場合</li>
              <li>統計的情報を提供する目的で、個々の利用者情報を集積または分析し、その統計データを開示する場合</li>
              <li>法令により開示又は提供が認められる場合</li>
            </ol>
            <h4>5. 免責</h4>
            <p>次に掲げる場合には、第三者による利用者情報の取得に関し、本アプリの運営者は一切の責任を負いません。</p>
            <ol>
              <li>ユーザーご本人が本アプリの機能または別の手段を用いて第三者に利用者情報を明らかにした場合</li>
              <li>ユーザーが本アプリ上に入力した情報等により、個人が識別できてしまった場合</li>
            </ol>
            <h4>6. プライバシーポリシーの改定について</h4>
            <p>本アプリの運営者は、予告なくしてプライバシーポリシーの全部又は一部を改定することがあります。変更した場合には、本サイトでお知らせします。</p>
            <h4>7. 問い合わせ先</h4>
            <a href="https://forms.gle/wAz4FEQD1f6LTzGX9" target="_blank" rel="noopener noreferrer">お問い合わせフォーム</a>
          </section>
        </div>
      </Layout>
    )
  }
}

export default AppPrivacy
